import React from "react"
import { graphql } from "gatsby"
import MDXRoot from "gatsby-plugin-mdx/wrap-root-element"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SiteHead from "../../head"

export function Head(props) {
  return <SiteHead {...props} title={props.data.mdx.frontmatter.title} />
}

export default function PrivacyPolicyPage({ data }) {
  return (
    <>
      <div style={{ maxWidth: 1024, margin: `24px auto`, padding: "64px" }}>
        <MDXRoot>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </MDXRoot>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
      }
    }
  }
`
